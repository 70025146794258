// Vendors
import $ from "jquery";
import Vue from 'vue';

// Vue vendor modules
import slick from 'vue-slick';
import VueScrollTo from "vue-scrollto";
import Sticky from 'vue-sticky-directive'
import * as VueGoogleMaps from 'vue2-google-maps';
import FloatLabel from 'vue-float-label/components/FloatLabel'

// Other JS vendor modules
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Custom Vue Components

class App {

  constructor() {

    // jQuery enkel nodig voor Slick Slider
    window.jQuery = $;
    window.$ = $;

    Vue.use(VueScrollTo, {
      container: "body",
      duration: 500,
      easing: "ease",
      offset: -100,
      force: true,
      cancelable: true,
      x: false,
      y: true
    });

    Vue.use(Sticky);

    Vue.use(VueGoogleMaps, {
      load: {
          key: 'AIzaSyARCS0suNm6lRXb3xm0MoRLccjmiX5PM8k',
          language: 'nl-BE',
          region: 'be',
      }
    });

  };

  init() {

    var FormBuilderSubmitted = document.getElementById("FormBuilderSubmitted");
    if (FormBuilderSubmitted) {
      window.location.hash = '#contact';
    }

    new Vue({
      el: '#app',

      delimiters: ['<%', '%>'],

      components: {
        'slick': slick,
        'google-map': VueGoogleMaps.Map,
        'gmap-marker' : VueGoogleMaps.Marker,
        FloatLabel,
      },

      data() {
        return {
          slickOptions: {
            slidesToShow: 3,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            arrows: true,
            dots: true,
            centerMode: true,
            focusOnSelect: false,
            infinite: true,
            accessibility: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 639,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                }
              }
            ]
          },
          menuIsActive: false,
          window: {
            width: 0,
            height: 0,
          },
          maxNavHeight: 0,
          subNavsContainers: [],
          subNavs: [],
        }
      },

      mounted() {
        this.subNavsContainers = this.$el.querySelectorAll('.c-nav-menu-item-has-sub');
        this.subNavs = this.$el.querySelectorAll('.c-nav-menu-sub');
        this.subNavsContainers.forEach((element) => {
          const node = document.createElement('span');
          node.classList.add("toggle-link");
          element.insertBefore(node, element.firstChild);
        })

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      },

      watch: {
        menuIsActive() {
          if (this.menuIsActive) {
            this.maxNavHeight = this.window.height - this.$refs.nav.clientHeight;
          } else {
            this.maxNavHeight = 0;
          }
        },
      },

      methods: {
        toggleActive() {
          // https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177
          this.menuIsActive = !this.menuIsActive
          if (this.menuIsActive) {
            disableBodyScroll();
          } else  {
            enableBodyScroll();
          }
        },
        menuClicked(event) {
          let targetClassNames = event.target.className.split(" ");
          targetClassNames.filter(e => {
            if (e === "toggle-link"){
              event.target.classList.toggle('active')
              const subMenu = event.target.parentNode.querySelector('ul');
              subMenu.classList.toggle('active');
             }
          });
        },
        handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
          if (this.window.width >= 960) {
            this.menuIsActive = false;
            this.subNavs.forEach((element) => {
              element.classList.remove('active');
              enableBodyScroll();
            })
          }
        },
      },

    });
  };
}

$(() => {
  const app = new App();
  app.init();
});
